<template>
  <div id="app">
    <app-sidebar v-if="isAuthenticated"></app-sidebar>
    <b-alert variant="danger">{{ $t(currentError) }}</b-alert>
    <app-menu v-if="isAuthenticated"></app-menu>
    <app-messages></app-messages>
    <router-view/>
  </div>
</template>
<script>
import AppMenu from "@/layout/AppMenu";
import AppSidebar from "@/layout/AppSidebar";
import AppMessages from "@/layout/AppMessages";
import {Security, SendsMessages} from "@/mixins";

export default {
  name: "app",
  mixins: [SendsMessages, Security],
  components: {AppMessages, AppMenu, AppSidebar},
  data() {
    return {
      currentError: undefined,
    }
  },
}
</script>
<style>
</style>
