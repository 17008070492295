import http from "@/http";
import Token from "@/token";
import eventBus from "@/events";
import Constants from '@/constants';

const Users = {
    currentUserId: undefined,
    currentUser: undefined,
    login: async function (credentials) {
        const auth = await http.post('/users/login', credentials);
        Token.setToken(auth.data.token, credentials.rememberMe);
    },
    sendRecoveryEmail: async function (email) {
        return http.get('users/password-recovery', {params: {usernameOrEmail: email}})
    },
    loadCurrentUser: async function (userId) {
        this.currentUserId = userId;
        this.currentUser = (await this.getCurrentUser()).data;
        console.log(this.currentUser);
    },
    getCurrentUser: function () {
        return http.get('users/current-user');
    },
    logout: async function () {
        try {
            await http.post('users/logout');
            Token.removeToken();
            this.currentUser = undefined;
            this.currentUserId = undefined;
            eventBus.$emit(Constants.EVENT_WHEN_LOGOUT);
        } catch (e) {
            console.error(e);
        }
    },
    onlyAvailable: async function(currentManager) {
        const resp = await http("/users/search/available", {params: {currentManager}});
        eventBus.$emit(Constants.EVENT_ON_USER_LIST, resp.data.page.totalElements);
        return resp.data['_embedded']['users'];
    },
    findAll: async function (ctx) {
        const page = ctx.currentPage - 1 || 0;
        const size = ctx.perPage || 20;
        const sortDesc = ctx.sortDesc ? ',desc' : '';
        const sort = (ctx.sortBy || 'number') + sortDesc;

        const filter = ctx.filter ? ctx.filter : {};
        const pagination = {page, size, sort};
        const params = {...filter, ...pagination};
        const resp = await http("/users/search/all", {params});
        eventBus.$emit(Constants.EVENT_ON_USER_LIST, resp.data.page.totalElements);
        return resp.data['_embedded']['users'];
    },
    findById: async function (id) {
        return http.get('/users/' + id);
    },
    create: function (data) {
        return http.post('/users', data);
    },
    update: function (id, data) {
        return http.patch('/users/' + id, data);
    },
    usernameExists(username) {
        return http.get("/users/exists/username", {params: {username}}).then(x => {
            return !x.data;
        });
    },
    emailExists(email) {
        return http.get("/users/exists/email", {params: {email}}).then(x => {
            return !x.data;
        });
    },
    deleteById(id) {
        return http.delete("/users/" + id);
    },
    enable(id) {
        return http.patch('/users/' + id + '/enable');
    },
    disable(id) {
        return http.patch('/users/' + id + '/disable');
    },
    resetPassword(userId, data) {
        return http.post('/users/' + userId + '/reset-password', data);
    },
    setNewPassword(password, verification, token) {
        token = token || Token.accessToken;
        return http.post('/users/password-recovery', {
            password,
            verification
        }, {headers: {Authorization: `Bearer ${token}`}});
    },
    getCurrentUserProfiles() {
        return http.get("users/current-user/profiles");
    },
    getCurrentUserPermissions() {
        return http.get("/users/current-user/permissions");
    }
};

export default Users;
