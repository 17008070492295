<template>
  <div>
    <b-navbar type="dark" variant="dark">
      <b-button class="mr-1" variant="dark" @click="goBack">
        <b-icon-chevron-left></b-icon-chevron-left>
      </b-button>
      <b-button id="menu-toggle-button" variant="dark" v-b-toggle.menu-1>
        <b-icon-list></b-icon-list>
      </b-button>

      <b-navbar-brand>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b-icon-person-circle class="mr-1"></b-icon-person-circle>
              <em>{{user.fullName}}</em>
            </template>
            <b-dropdown-item @click="profile" href="#">{{$t('PROFILE_LABEL') | capitalize}}</b-dropdown-item>
            <b-dropdown-item @click="logout" href="#">{{$t('LOGOUT_LABEL') | capitalize}}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>

</template>

<script>
import Users from "@/users";
import {CapitalFilter} from "@/mixins";

export default {
  name: "app-menu",
  mixins: [CapitalFilter],
  mounted() {
    this.user = Users.currentUser;
    if(this.$route.name === 'LOGIN_PAGE_TITLE') {
      this.$router.push({name: 'HOME_PAGE'})
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    async logout() {
      await Users.logout();
      await this.$router.push({path: '/login'})
    },
    profile() {
      this.$router.push({path: '/profile'});
    },
  },
  data() {
    return {
      user: {fullName: this.$t('NO_USER_LABEL')}
    }
  }
}
</script>

<style scoped>

</style>
