<template>
  <b-sidebar bg-variant="dark" text-variant="light" id="menu-1" :title="$t('MENU_LABEL') | capitalize" shadow backdrop
             backdrop-variant="dark">
    <div v-for="group in groups" :key="group.id">
      <b-button class="btn text-light text-left w-100 d-block border-bottom rounded-0" v-b-toggle="group.name">{{$t(group.name) | capitalize}}</b-button>
      <b-collapse :id="group.name" class="mt-2">
        <router-link
            class="btn text-light text-left w-100 d-block border-bottom rounded-0"
            v-for="item in group.items.sort((a,b) => a.order - b.order)"
            :key="item.order"
            :to="{name: item.name}">
          <b-icon :icon="item.icon"></b-icon>
          <span class="ml-1">{{ $t(item.label) | capitalize }}</span>
        </router-link>
      </b-collapse>
    </div>
  </b-sidebar>
</template>

<script>
import Menu from "@/menu";
import {CapitalFilter} from "@/mixins";

export default {
  name: "AppSidebar",
  mixins: [CapitalFilter],
  computed: {
    groups() {
      return Menu.items.sort((a,b) => a.order - b.order);
    }
  }
}
</script>

<style scoped>

</style>
