import VueI18n from 'vue-i18n';
import Vue from "vue";

const messages = {
    es: {
        POLICY_HAS_NO_DETAILS: 'la poliza no tiene movimientos, esto podria indicar que falta configurar cuentas y segmentos',
        POLICY_HAS_NO_INVOICES: 'la poliza no tiene facturas, esto podria ser un error',
        POLICY_HAS_NO_MESSAGES: 'la poliza no tiene mensajes, esto es normal',
        LIST_SHOW_POLICY_LABEL: 'ver poliza',
        POLICY_ACCOUNT_LABEL: 'cuenta',
        POLICY_DATE_LABEL: 'fecha',
        POLICY_CONCEPT_LABEL: 'concepto',
        POLICY_AMOUNT_LABEL: 'cantidad',
        POLICY_TYPE_LABEL: 'cargo/abono',
        POLICY_SEGMENT_LABEL: 'segmento',
        POLICY_LABEL: 'poliza',
        POLICY_PREVIEW_TITLE: 'vista previa de la poliza',
        POLICY_DETAILS_LABEL: 'detalles de la poliza',
        POLICY_INVOICES_LABEL: 'facturas de la poliza',
        POLICY_MESSAGES_LABEL: 'mensajes de la poliza',
        PAYMENT_POL_NUMBER_LABEL: 'poliza',
        PROJECT_BUDGET_ACCOUNT_LABEL: 'cuenta contable',
        PROJECT_BUDGET_BUSINESS_SEGMENT_LABEL: 'segmento de negocio',
        BANK_ACCOUNT_ORGANIZATION_LABEL: 'organizacion',
        BANK_ACCOUNT_ACCOUNT_LABEL: 'cuenta contable',
        BANK_ACCOUNT_BUSINESS_SEGMENT_LABEL: 'nombre del segmento de negocio',
        RET_ISR_10: 'Retencion de ISR',
        RET_IVA: 'Retencion de IVA',
        IVA_8: 'IVA',
        FORMS_ORGANIZATION_ACCOUNTS_ADD: 'agregar cuenta',
        ORGANIZATION_ACCOUNT_TYPE_LABEL: 'tipo de cuenta',
        ORGANIZATION_DATABASE_LABEL: 'base de datos (CONTPAQ I Contabilidad)',
        ORGANIZATION_ACCOUNTS_TITLE: 'cuentas contables',
        FILTER_AUTHORIZED_LABEL: 'requisiciones autorizadas',
        FILTER_UNAUTHORIZED_LABEL: 'requisiciones no autorizadas',
        FILTER_LABEL: 'opciones de filtrado y paginado',
        PROJECT_FILTER_ALL_LABEL: '-- Todos los proyectos --',
        REPORT_GENERATE_LABEL: 'generar',
        F70_REQUISITION_LABEL: 'requisicion',
        F70_BUDGET_LABEL: 'presupuesto',
        F70_PAYMENT_DATE_LABEL: 'fecha de pago',
        F70_INVOICE_DATE_LABEL: 'fecha de la factura',
        F70_INVOICE_LABEL: 'factura',
        F70_SUPPLIER_LABEL: 'proveedor',
        F70_TOTAL_LABEL: 'monto',
        F70_DESCRIPTION_LABEL: 'descripcion',
        F70_DETAIL_LABEL: 'detalles',
        F70_EXPENDITURE_LABEL: 'partida',
        F70_DONOR_LABEL: 'donador',
        ALL_EXPENDITURE_TYPES_LABEL: '-- Todos los tipos de gasto --',
        ALL_SUPPLIERS_LABEL: '-- Todos los proveedores --',
        FROM_LABEL: 'desde la fecha',
        TO_LABEL: 'hasta la fecha',
        GENERATE_REPORT_BUTTON_LABEL: 'generar reporte',
        REPORT_F70_PERMISSION: 'permiso para ver y exportar a excel reporte F70',
        REPORT_F70: 'reporte F70',
        REPORT_F70_PAGE_TITLE: 'Reporte F70',
        REQUISITION_LIST_FILES_PERMISSION: 'permiso para ver y subir archivos de la requisicion',
        INVOICE_UUID_REPEATED_SERVER: 'la factura cargada ya existe en otra requisicion.',
        INVOICE_UUID_REPEATED_CURRENT: 'la factura cargada ya existe en esta requisicion.',
        XML_UPLOAD_LABEL: 'cargar factura desde xml',
        REQUISITION_AUTHORIZE_LABEL: 'autorizar para pago',
        REQUISITION_DE_AUTHORIZE_LABEL: 'desautorizar de pago',
        REQUISITION_AUTHORIZE_PERMISSION: 'permiso para autorizar requisiciones a pago',
        REQUISITION_DE_AUTHORIZE_PERMISSION: 'permiso para desautorizar requisiciones de pago',
        REQUISITION_AUTHORIZE_WARNING: 'se autorizara para pago la requisicion {number}, ya no se podra modificar, desea continuar?',
        REQUISITION_DE_AUTHORIZE_WARNING: 'se desautorizara la requisicion {number}, desea continuar?',
        REQUISITION_AUTHORIZED_LABEL: 'autorizada',
        APPLICATION_LOG: 'bitacora',
        APPLICATION_LOG_LIST_ALL_PERMISSION: 'permiso para ver la bitacora',
        CANNOT_LOAD_INVOICES_YET: 'Para cargar facturas debe primero seleccionar el proyecto, tipo de gasto y proveedor',
        ERROR_UPLOADING_INVOICE_XML_RECEPTOR_RFC: 'El rfc del receptor no coincide con el RFC de la organizacion',
        ERROR_UPLOADING_INVOICE_XML_SENDER_RFC: 'El rfc del emisor no coincide con el RFC del proveedor',
        REQUISITION_INVOICE_SUPPLIER_RFC_LABEL: 'R.F.C. del proveedor',
        REQUISITION_INVOICE_RFC_LABEL: 'R.F.C.',
        PAYMENT_NUMBER_LABEL: 'folio',
        REQUISITION_BALANCE_LABEL: 'saldo',
        REQUISITION_PAID_LABEL: 'pagos',
        PAYMENT_DONOR_ACCOUNT_LABEL: 'cuenta del donador',
        PAYMENT_SUPPLIER_ACCOUNT_LABEL: 'cuenta del proveedor',
        REQUISITION_PDF_LABEL: 'generar PDF',
        PAYMENT_PDF_LABEL: 'generar PDF',
        PAYMENT_EXPENDITURE_TYPE_LABEL: 'tipo de gasto',
        REQUISITION_DONOR_LABEL: 'donador',
        REQUISITION_SUPPLIER_LABEL: 'proveedor',
        CANNOT_ADD_BUDGET_EXCEEDED: 'no se puede agregar la factura ya que excede el presupuesto por {by}',
        REQUISITION_INVOICE_CURRENCY_LABEL: 'moneda',
        REQUISITION_INVOICE_EXCHANGE_RATE_LABEL: 'tipo de cambio',
        FILTER_FROM_LABEL: 'desde',
        FILTER_TO_LABEL: 'hasta',
        FILTER_SEARCH_BY_TEXT_LABEL: 'buscar por texto',
        FILTER_SEARCH_BY_NUMBER_LABEL: 'buscar por folio',
        REQUISITION_TOTAL_LABEL: 'total',
        BUDGET_AFTER: 'prespuesto luego de esta requisicion',
        BUDGET_SPENT: 'presupuesto gastado en esta requisicion',
        BUDGET_FOR: 'presupuesto para',
        NO_BUDGET_FOUND: 'presupuesto no encontrado',
        REQUISITION_INVOICE_OPTIONS_XML: 'xml',
        REQUISITION_INVOICE_OPTIONS_PDF: 'pdf',
        INVOICE_PDF_UPLOADED: 'Archivo pdf cargado',
        INVOICE_XML_UPLOADED: 'Archivo xml cargado',
        REQUISITION_INVOICE_UPLOAD_XML: 'cargar xml',
        REQUISITION_INVOICE_UPLOAD_PDF: 'cargar pdf',
        REQUISITION_INVOICE_OPTIONS_LABEL: 'opciones',
        REQUISITION_INVOICE_DATE_LABEL: 'fecha',
        REQUISITION_INVOICE_NUMBER_LABEL: 'folio',
        REQUISITION_INVOICE_IVA_LABEL: 'IVA',
        REQUISITION_INVOICE_RET_IVA_LABEL: 'retencion IVA',
        REQUISITION_INVOICE_RET_ISR_LABEL: 'retencion ISR',
        REQUISITION_INVOICE_SUBTOTAL_LABEL: 'subtotal',
        REQUISITION_INVOICE_TOTAL_LABEL: 'total',
        REQUISITION_INVOICE_BALANCE_LABEL: 'saldo',
        FORMS_ERROR_FIELD_MAXVALUE: 'el campo "{field}" no puede tener un valor mas alto que el saldo',
        DONOR: 'donador',
        SUPPLIER: 'proveedor',
        PAYMENT_AMOUNT_LABEL: 'cantidad a pagar',
        REQUISITION_INVOICES_LABEL: 'facturas',
        REQUISITION_PERSONAL_LABEL: 'rembolso personal',
        REQUISITION_PERSONAL_ACCOUNT_LABEL: 'Cuenta personal',
        REQUISITION_SUPPLIER_ACCOUNT_LABEL: 'cuenta bancaria proveedor',
        APPLICATION_LOG_PERMISSION_CATEGORY: 'Bitacora',
        FORMS_DETAIL_ADD: 'agregar detalle',
        PROJECT_BUDGET_DESCRIPTION_LABEL: 'tipo de gasto',
        PROJECT_DETAIL_CURRENCY_MXN: 'pesos mexicanos',
        PROJECT_DETAIL_CURRENCY_USD: 'dolar estadounidense',
        PROJECT_BUDGET_AMOUNT_LABEL: 'monto',
        PROJECT_BUDGET_CURRENCY_LABEL: 'moneda',
        FORMS_ERROR_FIELD_DECIMAL: 'el campo "{field}" debe tener un valor numerico',
        PROJECT_BUDGET_DETAILS: 'presupuesto',
        FORMS_ERROR_FIELD_MINVALUE: 'el campo {field} no puede tener una fecha menor a la fecha de inicio del proyecto',
        PROJECT_USERS_LABEL: 'usuarios con acceso al proyecto',
        PROJECT_ORGANIZATION_LABEL: 'seleccione la organizacion duena del proyecto',
        DONOR_COMPANIES_LABEL: 'donadores',
        PROJECTS_INVITED_ORGANIZATIONS_LABEL: 'organizaciones invitadas',
        COMPANY_TYPE_ALL_LABEL: 'todos',
        FORMS_BANK_ACCOUNT_TITLE: 'Cuentas bancarias',
        MANAGER: 'administrador',
        FORMS_SELECT_ONE: '-- seleccione uno --',
        ACCOUNTS: 'cuentas',
        COMPANIES: 'empresas',
        PROJECTS: 'proyectos',
        REQUISITIONS: 'requisiciones',
        ORGANIZATIONS: 'organizaciones',
        INVOICES: 'facturas',
        PAYMENTS: 'pagos',
        EXPENDITURE_TYPES: 'tipos de gastos',
        BANK_ACCOUNTS: 'cuentas bancarias',
        ORGANIZATION_MANAGER_LABEL: 'administrador',
        USER_HAS_NO_PERMISSION: 'el usuario no tiene permiso para acceder a esta opcion',
        HOME_PAGE: 'pagina de inicio',
        HOME_PAGE_MESSAGE: 'pagina inicial por defecto, seleccione algun elemento del menu para iniciar.',
        LOGIN_PAGE_TITLE: 'iniciar sesión',
        FULL_NAME_LABEL: 'nombre completo',
        LOGIN_BUTTON_LABEL: 'iniciar sesión',
        LOGOUT_LABEL: 'cerrar sesión',
        REMEMBER_ME_LABEL: 'guardar sesión en este dispositivo',
        PASSWORD_RECOVERY_LINK_LABEL: 'olvido su contraseña?',
        RECOVERY_PAGE_TITLE: 'recuperar contraseña',
        RECOVER_BUTTON_LABEL: 'enviar correo de recuperación',
        SELECT_OPTION_LABEL: '-- seleccione uno --',
        USER_LOGIN_ERROR_MESSAGE: 'ocurrio un error al iniciar sesión, por favor verifique sus datos e intente nuevamente.',
        RECOVERY_MAIL_SENT_SUCCESS_MESSAGE: 'el correo de recuperación ha sido enviado.',
        RESET_PASSWORD_PAGE_TITLE: 'defina su nueva contraseña.',
        RESET_PASSWORD_BUTTON_LABEL: 'reestablecer contraseña.',
        RESET_MAIL_SUCCESS_MESSAGE: 'su contraña ha sido redefinida.',
        USER_LOGIN_SUCCESS_MESSAGE: 'bienvenido {username}',
        RESET_MAIL_ERROR_MESSAGE: 'ocurrio un error al redefinir su contraseña verifique que el correo de reestablecimiento siga vigente o genere una nueva solicitud de cambio.',
        MENU_LABEL: 'menu',
        CATALOGS: 'catalogos',
        ALL_RESULTS_PER_PAGE: 'todos los resultados',
        GRID_ROW_TOTAL: 'página {page} de {totalpages} - mostrando {size} registros por página',
        GRID_ALL_ROWS_TOTAL: 'mostrando todos los resultados disponibles',
        CATALOG_LOADING_LABEL: 'cargando {catalog}',
        MALE_GENDER: 'el',
        FEMININE_GENDER: 'la',
        LIST_PAGE_TITLE: 'listado de {catalog}',
        REGISTER_PAGE_TITLE: 'registrar {catalog}',
        EDIT_PAGE_TITLE: 'editar {catalog}',
        FILE: 'archivo',
        FILES: 'archivos',
        FILE_MODAL_TITLE: 'archivos',
        FILE_UPLOAD_LABEL: 'cargar archivo',
        FILE_LIST_TO_EXCEL_LABEL: 'bajar el listado de archivos a excel',
        FILE_DOWNLOAD_ALL_LABEL: 'descargar todo lo seleccionado',
        FILE_NAME_LABEL: 'nombre del archivo',
        FILE_TYPE_LABEL: 'tipo de archivo',
        FILE_SIZE_LABEL: 'tamaño del archivo',
        FILE_DELETE_ALL_LABEL: 'eliminar todo lo seleccionado',
        FILE_DOWNLOAD_LABEL: 'descargar archivo',
        FILE_DELETE_LABEL: 'eliminar archivo',
        FILE_UPLOADED_SUCCESS: 'archivo {file} ha sido cargado.',
        FILE_CONFIRM_SELECTION_LABEL: 'confirmar selección',
        FILE_SELECTED_LABEL: '{count} archivos seleccionados',
        LIST_ENABLED_LABEL: 'habilitado',
        LIST_OPTIONS_LABEL: 'opciones',
        LIST_ADD_LABEL: 'nuevo {catalog}',
        LIST_REFRESH_LABEL: 'refrescar',
        LIST_FILTER_CLEAR_LABEL: 'limpiar filtro',
        LIST_EXCEL_DOWNLOAD_LABEL: 'exportar a excel',
        LIST_EDIT_LABEL: 'editar',
        LIST_DELETE_LABEL: 'eliminar',
        LIST_ENABLE_LABEL: 'habilitar',
        LIST_DISABLE_LABEL: 'deshabilitar',
        LIST_LOADING_LABEL: 'cargando {catalog}',
        LIST_SEARCH_LABEL: 'buscar {catalog}',
        LIST_FILES_LABEL: 'archivos',
        LIST_ALL_OPTIONS_LABEL: 'todas',
        LIST_CONFIRM_DELETE: 'desea eliminar {gender} {catalog} "{identifier}"?',
        LIST_CONFIRM_ENABLE: 'desea habilitar {gender} {catalog} "{identifier}"?',
        LIST_CONFIRM_DISABLE: 'desea deshabilitar {gender} {catalog} "{identifier}"?',
        LIST_DELETE_OK: '{gender} {catalog} {identifier} ha sido eliminado.',
        LIST_DISABLE_OK: '{gender} {catalog} {identifier} ha sido deshabilitado.',
        LIST_ENABLE_OK: '{gender} {catalog} {identifier} ha sido habilitado.',
        LIST_DELETE_ERROR: 'ocurre un error al eliminar {gender} {catalog} {identifier}.',
        LIST_ENABLE_ERROR: 'ocurre un error al habilitar {gender} {catalog} {identifier}.',
        LIST_DISABLE_ERROR: 'ocurre un error al deshabilitar {gender} {catalog} {identifier}.',
        LIST_MESSAGE_CLOSING_LABEL: 'este mensaje se cerrara en {life} segundos',
        PAGINATION_RESULTS_PER_PAGE: '{size} resultados por página',
        FILTER_SEARCH_LABEL: 'buscar {catalog}',
        FILTER_ALL_LABEL: 'tod{gender}s l{gender}s {catalog}',
        FILTER_ACTIVE_LABEL: 'tod{gender}s l{gender}s activ{gender}s',
        FILTER_INACTIVE_LABEL: 'tod{gender}s l{gender}s inactiv{gender}s',
        SEARCH_FROM_DATE_LABEL: 'desde la fecha',
        SEARCH_TO_DATE_LABEL: 'hasta la fecha',
        FORMS_GENERAL_DATA_TITLE: 'datos generales',
        FORMS_SAVE_LABEL: 'guardar',
        FORMS_SAVING_LABEL: 'guardando...',
        FORMS_ERROR_CREATING: 'error al registrar {gender} {catalog}',
        FORMS_ERROR_UPDATING: 'error al registrar {gender} {catalog}',
        FORMS_FILE_UPLOAD_LABEL: 'seleccionar archivo',
        FORMS_GENDER_MALE_LABEL: 'masculino',
        FORMS_GENDER_FEMALE_LABEL: 'femenino',
        FORMS_ERROR_FIELD_REQUIRED: 'el campo {field} es requerido.',
        FORMS_ERROR_FIELD_EMAIL: 'el campo {field} es debe ser un correo electrónico valido.',
        FORMS_ERROR_FIELD_ISUNIQUE: 'el campo {field} es debe ser unico.',
        FORMS_ERROR_FIELD_MINLENGTH: 'el campo {field} debe tener una longitud minima de {min} caracteres.',
        FORMS_ERROR_FIELD_MAXLENGTH: 'el campo {field} debe tener una longitud maxima de {max} caracteres.',
        FORMS_ERROR_FIELD_SAMEAS: 'el campo "{field}" no coincide con el campo "{field2}".',
        FORMS_ERROR_FIELD_PATTERN: 'el campo "{field}" no cumple con el formato requerido.',
        FORMS_MULTI_SELECT_LABEL: 'seleccionar {catalog}',
        FORM_EVENT_UPDATED: '{catalog} actualizado.',
        FORM_EVENT_CREATED: '{catalog} registrado',
        PROFILE: 'perfil',
        PROFILES: 'perfiles',
        PROFILE_NAME_LABEL: 'nombre del perfil',
        PROFILE_LABEL: 'ver perfil',
        PROFILE_NO_USER_LABEL: 'no hay usuario?',
        PROFILE_PAGE_TITLE: 'perfil del usuario',
        PROFILE_USER_LABEL: 'perfil del usuario "{username}"',
        PROFILE_USER_PERMISSIONS_LABEL: 'permisos asignados al usuario "{username}"',
        PROFILE_USER_PROFILES_LABEL: 'perfiles asignados al usuario "{username}"',
        PROFILE_NO_PERMISSIONS_LABEL: 'el usuario {username} no tiene permisos asignados',
        PROFILE_NO_PROFILES_LABEL: 'el usuario {username} no tiene perfiles asignados',
        PROFILE_ROOT_PERMISSIONS_LABEL: 'el usuario administrador tiene todos los permisos, no es necesario asignar permisos adicionales',
        PROFILE_ROOT_PROFILES_LABEL: 'el usuario administrador tiene el perfil mas alto del sistema, otros perfiles son innecesarios',
        PROFILE_USER_LOADING_LABEL: 'cargando perfil de usuario',
        USER: 'usuario',
        USERS: 'usuarios',
        USER_USERNAME_LABEL: 'usuario',
        USER_NAME_LABEL: 'nombre',
        USER_LAST_NAME_LABEL: 'apellidos',
        USER_EMAIL_LABEL: 'correo electrónico',
        USER_PASSWORD_LABEL: 'contraseña',
        USER_FULL_NAME_LABEL: 'nombre completo',
        USER_ENABLED_LABEL: 'usuario habilitado?',
        USER_PASSWORD_VERIFICATION_LABEL: 'verificar la contraseña',
        USER_GENDER_LABEL: 'sexo',
        USER_AVATAR_IMG_LABEL: 'avatar usuario',
        USER_ERROR_CHANGING_PASSWORD: 'error al cambiar la contraseña, por favor verifique su información e intente nuevamente.',
        USER_PERMISSIONS_LABEL: 'permisos del usuario',
        ROOT_PERMISSIONS_LABEL: 'este usuario es el administrador del sistema, tiene todos los permisos',
        NO_PERMISSIONS_LABEL: 'este usuario no tiene permisos adicionales asignados.',
        USER_PROFILES_LABEL: 'perfiles del usuario',
        ROOT_PROFILES_LABEL: 'este usuario tiene el perfil mas alto del sistema.',
        NO_PROFILES_LABEL: 'este usuario no tiene perfil(es) asignado(s).',
        SESSION: 'sesion',
        SESSIONS: 'sesiones',
        SESSION_IP_LABEL: 'ip',
        SESSION_TYPE_LABEL: 'tipo de sesión',
        SESSION_USERNAME_LABEL: 'usuario',
        SESSION_USER_AGENT_LABEL: 'navegador',
        SESSION_EXPIRATION_LABEL: 'fecha expiración',
        ACCESS_TOKEN_LABEL: 'sesión normal',
        PASSWORD_RECOVERY_TOKEN_LABEL: 'sesión para reestablecer contraseña',
        LOG: 'bitacora',
        LOGS: 'bitacoras',
        LOG_DATE_LABEL: 'fecha',
        LOG_ALL_CATALOGS: 'todos los catalogos',
        LOG_CATALOG_LABEL: 'catalogo/modulo',
        LOG_OPERATION_LABEL: 'operación',
        LOG_DATA_LABEL: 'datos o identificador',
        LOG_ALL_OPERATIONS_LABEL: 'todas las operaciones',
        LOG_CREATED_LABEL: 'registro',
        LOG_UPDATED_LABEL: 'actualización',
        LOG_ENABLED_LABEL: 'habilitado',
        LOG_DISABLED_LABEL: 'deshabilitado',
        LOG_USERNAME_LABEL: 'usuario',
        LOG_DELETED_LABEL: 'eliminado',
        LOG_PASSWORD_CHANGE_LABEL: 'cambio de contraseña',
        USER_PASSWORD_CHANGE_LABEL: 'cambiar contraseña',
        USER_CHANGE_PASSWORD: 'cambiar la contraseña del usuario "{username}"',
        USER_PASSWORD_CHANGED: 'la contraseña del usuario "{username}" ha sido actualizada',
        PERMISSION: 'permiso',
        PERMISSIONS: 'permisos',
        PERMISSION_PERMISSION_CATEGORY: 'permisos',
        PERMISSION_CATEGORY_LABEL: 'categoria',
        PERMISSION_AUTHORITIES_LABEL: 'descripción',
        PROFILE_PERMISSION_CATEGORY: 'perfiles',
        USER_PERMISSION_CATEGORY: 'usuarios',
        SESSION_PERMISSION_CATEGORY: 'sesiones',
        PERMISSION_LIST_ALL_PERMISSION: 'permiso para consultar todos los permisos disponibles.',
        PROFILE_CREATE_PERMISSION: 'permiso para crear nuevos perfiles',
        PROFILE_DELETE_PERMISSION: 'permiso para borrar perfiles permanentemente',
        PROFILE_DISABLE_PERMISSION: 'permiso para deshabilitar perfiles',
        PROFILE_ENABLE_PERMISSION: 'permiso para habilitar perfiles',
        PROFILE_LIST_ALL_PERMISSION: 'permiso acceder a la pagina de permisos',
        PROFILE_UPDATE_PERMISSION: 'permiso para actualizar perfiles',
        SESSION_DELETE_PERMISSION: 'permiso para cerrar sesiones de cualquier usuario.',
        SESSION_LIST_ALL_PERMISSION: 'permiso para acceder a la pagina de sesiones',
        USER_CREATE_PERMISSION: 'permiso para crear nuevos usuarios',
        USER_DELETE_PERMISSION: 'permiso para eliminar permanentemente usuarios',
        USER_DETAIL_PERMISSION: 'permiso para ver los detalles de los usuarios',
        USER_DISABLE_PERMISSION: 'permiso para deshabilitar usuarios',
        USER_ENABLE_PERMISSION: 'permiso para habilitar usuarios',
        USER_LIST_ALL_PERMISSION: 'permiso para acceder a la pagina de usuarios',
        USER_PASSWORD_CHANGE_PERMISSION: 'permiso para cambiar la contraseña de cualquier usuario',
        USER_PASSWORD_RECOVERY_PERMISSION: 'permiso para enviar el correo de recuperación de la contraseña de cualquier usuario',
        USER_UPDATE_PERMISSION: 'permiso para editar usuarios',
        ACCOUNT_LABEL: 'cuenta',
        ACCOUNTS_LABEL: 'cuentas',
        ACCOUNT: 'cuenta',
        ACCOUNT_CREATE_PERMISSION: 'permiso para registrar cuentas',
        ACCOUNT_UPDATE_PERMISSION: 'permiso para editar cuentas',
        ACCOUNT_DELETE_PERMISSION: 'permiso para eliminar cuentas',
        ACCOUNT_DISABLE_PERMISSION: 'permiso para deshabilitar cuentas',
        ACCOUNT_ENABLE_PERMISSION: 'permiso para habilitar cuentas',
        ACCOUNT_LIST_ALL_PERMISSION: 'permiso para ver la pagina de cuentas',
        ACCOUNT_CODE_LABEL: 'codigo',
        ACCOUNT_NAME_LABEL: 'descripcion',
        COMPANY_LABEL: 'empresa',
        COMPANIES_LABEL: 'empresas',
        COMPANY: 'empresa',
        COMPANY_CREATE_PERMISSION: 'permiso para registrar empresas',
        COMPANY_UPDATE_PERMISSION: 'permiso para actualizar empresas',
        COMPANY_DELETE_PERMISSION: 'permiso para eliminar empresas',
        COMPANY_DISABLE_PERMISSION: 'permiso para deshabilitar empresas',
        COMPANY_ENABLE_PERMISSION: 'permiso para habilitar empresas',
        COMPANY_LIST_ALL_PERMISSION: 'permiso para ver la pagina de empresas',
        COMPANY_RFC_LABEL: 'r.f.c.',
        COMPANY_NAME_LABEL: 'razon social',
        COMPANY_EMAIL_LABEL: 'correo electronico',
        COMPANY_TYPE_LABEL: 'tipo de empresa',
        PROJECT_LABEL: 'proyecto',
        PROJECTS_LABEL: 'proyectos',
        PROJECT: 'proyecto',
        PROJECT_CREATE_PERMISSION: 'permiso para registrar proyectos',
        PROJECT_UPDATE_PERMISSION: 'permiso para actualizar proyectos',
        PROJECT_DELETE_PERMISSION: 'permiso para eliminar proyectos',
        PROJECT_DISABLE_PERMISSION: 'permiso para deshabilitar proyectos',
        PROJECT_ENABLE_PERMISSION: 'permiso para habilitar proyectos',
        PROJECT_LIST_ALL_PERMISSION: 'permiso para ver todos los proyectos',
        PROJECT_TITLE_LABEL: 'proyectos',
        PROJECT_BUDGET_LABEL: 'presupuesto',
        PROJECT_FROM_LABEL: 'desde',
        PROJECT_TO_LABEL: 'hasta',
        REQUISITION_LABEL: 'requisicion',
        REQUISITIONS_LABEL: 'requisiciones',
        REQUISITION: 'requisicion',
        REQUISITION_CREATE_PERMISSION: 'permiso para crear requisiciones',
        REQUISITION_UPDATE_PERMISSION: 'permiso para actualizar requisiciones',
        REQUISITION_DELETE_PERMISSION: 'permiso para eliminar requisiciones',
        REQUISITION_DISABLE_PERMISSION: 'permiso para deshabilitar requisiciones',
        REQUISITION_ENABLE_PERMISSION: 'permiso para habilitar requisiciones',
        REQUISITION_LIST_ALL_PERMISSION: 'permiso para ver todas las requisiciones',
        REQUISITION_CONCEPT_LABEL: 'concepto',
        REQUISITION_OBSERVATIONS_LABEL: 'observaciones',
        REQUISITION_PAYMENT_DATE_LABEL: 'fecha de pago',
        PAYMENT_DATE_LABEL: 'fecha de pago',
        REQUISITION_TYPE_LABEL: 'tipo',
        REQUISITION_ACCOUNT_LABEL: 'cuentas',
        REQUISITION_NUMBER_LABEL: 'folio',
        ORGANIZATION_LABEL: 'organizacion',
        ORGANIZATIONS_LABEL: 'organizaciones',
        ORGANIZATION: 'organizacion',
        ORGANIZATION_CREATE_PERMISSION: 'permiso para crear organizaciones',
        ORGANIZATION_UPDATE_PERMISSION: 'permiso para editar organizaciones',
        ORGANIZATION_DELETE_PERMISSION: 'permiso para borrar organizaciones',
        ORGANIZATION_DISABLE_PERMISSION: 'permiso para deshabilitar organizaciones',
        ORGANIZATION_ENABLE_PERMISSION: 'permiso para habilitar organizaciones',
        ORGANIZATION_LIST_ALL_PERMISSION: 'permiso para ver todas las organizaciones',
        ORGANIZATION_RFC_LABEL: 'r.f.c.',
        ORGANIZATION_NAME_LABEL: 'razon social',
        INVOICE_LABEL: 'factura',
        INVOICES_LABEL: 'facturas',
        INVOICE: 'factura',
        INVOICE_CREATE_PERMISSION: 'permiso para registrar facturas',
        INVOICE_UPDATE_PERMISSION: 'permiso para editar facturas',
        INVOICE_DELETE_PERMISSION: 'permiso para borrar facturas',
        INVOICE_DISABLE_PERMISSION: 'permiso para deshabilitar facturas',
        INVOICE_ENABLE_PERMISSION: 'permiso para habilitar facturas',
        INVOICE_LIST_ALL_PERMISSION: 'permiso para ver la pagina de facturas',
        INVOICE_NUMBER_LABEL: 'folio',
        INVOICE_DATE_LABEL: 'fecha de pago',
        INVOICE_AMOUNT_LABEL: 'cantidad',
        INVOICE_EXCHANGE_RATE_LABEL: 'tipo de cambio',
        INVOICE_CURRENCY_LABEL: 'moneda',
        PAYMENT_LABEL: 'pago',
        PAYMENTS_LABEL: 'pagos',
        PAYMENT: 'pago',
        PAYMENT_CREATE_PERMISSION: 'permiso para registrar pagos',
        PAYMENT_UPDATE_PERMISSION: 'permiso para editar pagos',
        PAYMENT_DELETE_PERMISSION: 'permiso para borrar pagos',
        PAYMENT_DISABLE_PERMISSION: 'permiso para deshabilitar pagos',
        PAYMENT_ENABLE_PERMISSION: 'permiso para habilitar pagos',
        PAYMENT_LIST_ALL_PERMISSION: 'permiso para ver todos los pagos pagos',
        PAYMENT_DONOR_LABEL: 'donador',
        PAYMENT_SEGMENT_LABEL: 'segmento de negocios',
        PAYMENT_ACCOUNT_LABEL: 'cuentas',
        PAYMENT_DUE_DATE_LABEL: 'fecha de pago',
        PAYMENT_TOTAL_LABEL: 'total',
        PAYMENT_CONCEPT_LABEL: 'concepto',
        PAYMENT_OBSERVATIONS_LABEL: 'observaciones',
        EXPENDITURE_TYPE_LABEL: 'tipo de gasto',
        EXPENDITURE_TYPES_LABEL: 'tipos de gasto',
        EXPENDITURE_TYPE: 'tipo de gasto',
        EXPENDITURE_TYPE_CREATE_PERMISSION: 'permiso para registrar tipo de gasto',
        EXPENDITURE_TYPE_UPDATE_PERMISSION: 'permiso para editar tipos de gasto',
        EXPENDITURE_TYPE_DELETE_PERMISSION: 'permiso para eliminar el tipo de gasto',
        EXPENDITURE_TYPE_DISABLE_PERMISSION: 'permiso para deshabilitar tipos de gasto',
        EXPENDITURE_TYPE_ENABLE_PERMISSION: 'permiso para habilitar tipos de gasto',
        EXPENDITURE_TYPE_LIST_ALL_PERMISSION: 'permiso para acceder al listado de tipos de gasto',
        EXPENDITURE_TYPE_NAME_LABEL: 'nombre',
        BANK_ACCOUNT_LABEL: 'bank_account_label',
        BANK_ACCOUNTS_LABEL: 'bank_accounts_label',
        BANK_ACCOUNT: 'cuenta bancaria',
        BANK_ACCOUNT_CREATE_PERMISSION: 'bank_account_create_permission',
        BANK_ACCOUNT_UPDATE_PERMISSION: 'bank_account_update_permission',
        BANK_ACCOUNT_DELETE_PERMISSION: 'bank_account_delete_permission',
        BANK_ACCOUNT_DISABLE_PERMISSION: 'bank_account_disable_permission',
        BANK_ACCOUNT_ENABLE_PERMISSION: 'bank_account_enable_permission',
        BANK_ACCOUNT_LIST_ALL_PERMISSION: 'BANK_ACCOUNT_LIST_ALL_PERMISSION',
        BANK_ACCOUNT_DESCRIPTION_LABEL: 'descripcion',
        BANK_ACCOUNT_CODE_LABEL: 'codigo',
        FORMS_BANK_ACCOUNTS_ADD: 'agregar cuenta',
        COMPANY_TYPE_DONOR_LABEL: 'donador',
        COMPANY_TYPE_SUPPLIER_LABEL: 'proveedor',
        COMPANY_TYPE_DONOR_SUPPLIER_LABEL: 'donador y proveedor',
    }
};

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'es',
    messages,
});

export default i18n;
