import Token from "@/token";

const Menu = {
    _items: [],
    get items() {
        this._items = [
            {
                id: 1,
                name: 'CATALOGS',
                items: []
            }
        ];

        if (Token.hasPermission('USER_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 1,
                name: 'USER_LIST_PAGE_TITLE',
                icon: 'person',
                label: 'USERS',
                permission: '',
            })
        }

        if (Token.hasPermission('PROFILE_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 2,
                name: 'PROFILE_LIST_PAGE_TITLE',
                icon: 'file-earmark-person',
                label: 'PROFILES'
            })
        }

        if (Token.hasPermission('SESSION_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 3,
                name: 'SESSION_LIST_PAGE_TITLE',
                icon: 'key',
                label: 'SESSIONS'
            })
        }

        if (Token.hasPermission('APPLICATION_LOG_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 4,
                name: 'LOG_LIST_PAGE_TITLE',
                icon: 'book',
                label: 'LOG'
            })
        }

        if (Token.hasPermission('COMPANY_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 5,
                name: 'COMPANY_LIST_PAGE_TITLE',
                icon: 'building',
                label: 'COMPANIES'
            })
        }

        this._items[0].items.push({
            order: 6,
            name: 'PROJECT_LIST_PAGE_TITLE',
            icon: 'folder',
            label: 'PROJECTS'
        })

        this._items[0].items.push({
            order: 7,
            name: 'REQUISITION_LIST_PAGE_TITLE',
            icon: 'file-earmark-medical-fill',
            label: 'REQUISITIONS'
        })

        this._items[0].items.push({
            order: 8,
            name: 'ORGANIZATION_LIST_PAGE_TITLE',
            icon: 'globe2',
            label: 'ORGANIZATIONS'
        })

        if (Token.hasPermission('EXPENDITURE_TYPE_LIST_ALL_PERMISSION')) {
            this._items[0].items.push({
                order: 9,
                name: 'EXPENDITURE_TYPE_LIST_PAGE_TITLE',
                icon: 'wallet',
                label: 'EXPENDITURE_TYPES'
            })
        }

        this._items[0].items.push({
            order: 10,
            name: 'PAYMENT_LIST_PAGE_TITLE',
            icon: 'currency-exchange',
            label: 'PAYMENTS'
        })


        if (Token.hasPermission('REPORT_F70_PERMISSION')) {
            this._items[0].items.push({
                order: 11,
                name: 'REPORT_F70_PAGE_TITLE',
                icon: 'file',
                label: 'REPORT_F70'
            })
        }

        return this._items;
    }
};
export default Menu;
