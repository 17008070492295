import Vue from 'vue'
import VueRouter from 'vue-router'
import Token from "@/token";
import Users from "@/users";
import eventBus from "@/events";
import Constants from "@/constants";

Vue.use(VueRouter)

const UserList = () => import('@/users/UserList');
const ReportList = () => import('@/reports/ReportsComponent');
const ProfileList = () => import('@/users/profiles/ProfileList');
const UserForm = () => import('@/users/UserForm');
const ProfileForm = () => import('@/users/profiles/ProfileForm');
const UserProfile = () => import('@/users/UserProfile');
const SessionList = () => import('@/users/sessions/SessionList');
const Login = () => import('../views/Login.vue');
const BasePage = () => import('@/views/BasePage');
const ApplicationLogList = () => import('@/log/ApplicationLogList');
const Recovery = () => import('@/views/Recovery');
const Reset = () => import('@/views/Reset');

const AccountForm = () => import('@/account/AccountForm');
const AccountList = () => import('@/account/AccountList');
const CompanyForm = () => import('@/company/CompanyForm');
const CompanyList = () => import('@/company/CompanyList');
const ProjectForm = () => import('@/project/ProjectForm');
const ProjectList = () => import('@/project/ProjectList');
const RequisitionForm = () => import('@/requisition/RequisitionForm');
const RequisitionList = () => import('@/requisition/RequisitionList');
const OrganizationForm = () => import('@/organization/OrganizationForm');
const OrganizationList = () => import('@/organization/OrganizationList');
const PaymentForm = () => import('@/payment/PaymentForm');
const PaymentList = () => import('@/payment/PaymentList');
const ExpenditureTypeList = () => import('@/expenditureType/ExpenditureTypeList');
const ExpenditureTypeForm = () => import('@/expenditureType/ExpenditureTypeForm');
const HomeComponent = () => import('@/home/HomeComponent');
const F70Report = () => import('@/reports/F70ReportComponent');

const routes = [
    {
        path: '/login',
        name: 'LOGIN_PAGE_TITLE',
        component: Login
    },
    {
        path: '/recovery',
        name: 'PASSWORD_RECOVERY_PAGE_TITLE',
        component: Recovery
    },
    {
        path: '/password-reset',
        name: 'PASSWORD_RESET_PAGE_TITLE',
        component: Reset
    },
    {
        path: '',
        name: 'HOME_PAGE_TITLE',
        component: BasePage,
        children: [
            {path: '', name: 'HOME_PAGE', component: HomeComponent},
        ]
    },
    {
        path: '/users',
        component: BasePage,
        children: [
            {path: '', name: 'USER_LIST_PAGE_TITLE', component: UserList},
            {path: 'register', name: 'USER_REGISTER_TITLE', component: UserForm},
            {path: ':id/edit', name: 'USER_EDIT_TITLE', component: UserForm}
        ]
    },
    {
        path: '/reports',
        component: BasePage,
        children: [
            {path: '', name: 'REPORT_LIST_PAGE_TITLE', component: ReportList},
        ]
    },
    {
        path: '/profile',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_PAGE_TITLE', component: UserProfile},
        ]
    },
    {
        path: '/profiles',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_LIST_PAGE_TITLE', component: ProfileList},
            {path: 'register', name: 'PROFILE_REGISTER_TITLE', component: ProfileForm},
            {path: ':id/edit', name: 'PROFILE_EDIT_TITLE', component: ProfileForm}
        ]
    },
    {
        path: '/sessions',
        component: BasePage,
        children: [
            {path: '', name: 'SESSION_LIST_PAGE_TITLE', component: SessionList}
        ]
    },
    {
        path: '/logs',
        component: BasePage,
        children: [
            {path: '', name: 'LOG_LIST_PAGE_TITLE', component: ApplicationLogList}
        ]
    },
    {
        path: '/account',
        component: BasePage,
        children: [
            {path: '', name: 'ACCOUNT_LIST_PAGE_TITLE', component: AccountList},
            {path: 'register', name: 'ACCOUNT_REGISTER_TITLE', component: AccountForm},
            {path: ':id/edit', name: 'ACCOUNT_EDIT_TITLE', component: AccountForm}
        ]
    }, {
        path: '/company',
        component: BasePage,
        children: [
            {path: '', name: 'COMPANY_LIST_PAGE_TITLE', component: CompanyList},
            {path: 'register', name: 'COMPANY_REGISTER_TITLE', component: CompanyForm},
            {path: ':id/edit', name: 'COMPANY_EDIT_TITLE', component: CompanyForm}
        ]
    }, {
        path: '/project',
        component: BasePage,
        children: [
            {path: '', name: 'PROJECT_LIST_PAGE_TITLE', component: ProjectList},
            {path: 'register', name: 'PROJECT_REGISTER_TITLE', component: ProjectForm},
            {path: ':id/edit', name: 'PROJECT_EDIT_TITLE', component: ProjectForm}
        ]
    }, {
        path: '/requisition',
        component: BasePage,
        children: [
            {path: '', name: 'REQUISITION_LIST_PAGE_TITLE', component: RequisitionList},
            {path: 'register', name: 'REQUISITION_REGISTER_TITLE', component: RequisitionForm},
            {path: ':id/edit', name: 'REQUISITION_EDIT_TITLE', component: RequisitionForm}
        ]
    }, {
        path: '/organization',
        component: BasePage,
        children: [
            {path: '', name: 'ORGANIZATION_LIST_PAGE_TITLE', component: OrganizationList},
            {path: 'register', name: 'ORGANIZATION_REGISTER_TITLE', component: OrganizationForm},
            {path: ':id/edit', name: 'ORGANIZATION_EDIT_TITLE', component: OrganizationForm}
        ]
    }, {
        path: '/payment',
        component: BasePage,
        children: [
            {path: '', name: 'PAYMENT_LIST_PAGE_TITLE', component: PaymentList},
            {path: 'register', name: 'PAYMENT_REGISTER_TITLE', component: PaymentForm},
            {path: ':id/edit', name: 'PAYMENT_EDIT_TITLE', component: PaymentForm}
        ]
    },
    {
        path: '/expenditureTypes',
        component: BasePage,
        children: [
            {path: '', name: 'EXPENDITURE_TYPE_LIST_PAGE_TITLE', component: ExpenditureTypeList},
            {path: 'register', name: 'EXPENDITURE_TYPE_REGISTER_TITLE', component: ExpenditureTypeForm},
            {path: ':id/edit', name: 'EXPENDITURE_TYPE_EDIT_TITLE', component: ExpenditureTypeForm}
        ]
    },
    {
        path: '/reports',
        component: BasePage,
        children: [
            {path: 'f70', name: 'REPORT_F70_PAGE_TITLE', component: F70Report},
        ]
    }
]

const router = new VueRouter({
    routes
})

router.beforeResolve(async (to, from, next) => {
    let permission;
    if (Token.isAuthenticated()) {
        let user = Users.currentUser;
        if (to.name === 'HOME_PAGE') {
            if (user && user.data && user.data.defaultPage) {
                next({name: user.data.defaultPage});
            }
        }
        switch (to.name) {
            case 'USER_LIST_PAGE_TITLE':
                permission = 'USER_LIST_ALL_PERMISSION';
                break;
            case 'PROFILE_LIST_PAGE_TITLE':
                permission = 'PROFILE_LIST_ALL_PERMISSION';
                break;
            case 'SESSION_LIST_PAGE_TITLE':
                permission = 'SESSION_LIST_ALL_PERMISSION';
                break;
            case 'LOG_LIST_PAGE_TITLE':
                permission = 'APPLICATION_LOG_LIST_ALL_PERMISSION';
                break;
            case 'COMPANY_LIST_PAGE_TITLE':
                permission = 'COMPANY_LIST_ALL_PERMISSION'
                break;
            case 'EXPENDITURE_TYPE_LIST_PAGE_TITLE':
                permission = 'EXPENDITURE_TYPE_LIST_ALL_PERMISSION'
                break;
            default:
                permission = undefined;
                break;
        }
        if (permission && !Token.hasPermission(permission)) {
            eventBus.$emit(Constants.EVENT_WHEN_MESSAGE_DISPLAY, 'USER_HAS_NO_PERMISSION', {page: to.name}, 'warning', undefined)
            next(from);
        } else {
            next();
        }
    } else {
        console.log('Not authenticated')
        const exceptions = [null, 'LOGIN_PAGE_TITLE', 'PASSWORD_RECOVERY_PAGE_TITLE', 'PASSWORD_RESET_PAGE_TITLE'];
        const name = to.name;
        if (exceptions.indexOf(name) === -1) {
            next({name: 'LOGIN_PAGE_TITLE'});
        } else {
            next();
        }
    }
});

export default router
